import Cookie from 'js-cookie';
import _noop from 'lodash/noop';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import omit from 'lodash/omit';
import superagent from '@ott/superagent';
import KM from '@ott/utility-km';
import { getLayoutContextValue } from '@ott/layout-context';

let metrics = _noop;

async function getSystemInfo(commonOptions) {
  const { body } = await superagent.get('/_api/system/info2').send().withCredentials();

  return Promise.resolve({
    ...commonOptions,
    ENVID: body.ENVID,
    referrer: body.referrer,
    referrer_mrk: body.referrer_mrk,
  });
}

function clearOptionsObject(options) {
  const keysToRemove = ['sid'];
  return omit(pickBy(options, identity), ...keysToRemove);
}

function metricsInitialize() {
  const vid = Cookie.get('vid');
  const sid = Cookie.get('sid');
  const abst = Cookie.get('abst');
  const acceptLanguage = Cookie.get('accept_language');
  const product = window.location.pathname.split('/').filter((v) => v)[1] || 'avia';
  const page = `index_${product}`;

  const getSystemInfoPromise = getSystemInfo({
    page,
    vid,
    sid,
    abst,
    accept_language: acceptLanguage,
  });

  return function metrics(type, additionalOptions) {
    getSystemInfoPromise.then((options) => {
      const clearedOptions = clearOptionsObject(options);
      const deviceOptions = { isMobile: getLayoutContextValue().isMobile };
      switch (type) {
        case 'index_show_page':
          KM('page_show', {
            obj: {
              ...clearedOptions,
              ...additionalOptions,
              ...deviceOptions,
            },
          });

          break;
        case 'start_search':
          KM('search_start', {
            obj: {
              ...clearedOptions,
              ...additionalOptions,
            },
          });

          break;
        case 'start_hotels_search': {
          KM('HOT_INDEX_CLICK_SEARCH', {
            obj: {
              ...clearedOptions,
              ...additionalOptions,
            },
          });

          break;
        }
        case 'start_hotels_suggest': {
          KM('HOT_SUGGEST_START', {
            obj: {
              ...additionalOptions,
              ...clearedOptions,
            },
          });
          break;
        }
        case 'success_hotels_suggest': {
          KM('HOT_SUGGEST_END', {
            obj: {
              ...additionalOptions,
              ...clearedOptions,
            },
          });
          break;
        }
        case 'error_hotels_suggest': {
          KM('HOT_SUGGEST_ERROR', {
            obj: {
              ...additionalOptions,
              ...clearedOptions,
            },
          });
          break;
        }
        case 'select_hotels_suggest': {
          KM('HOT_SUGGEST_SELECTED', {
            obj: {
              ...additionalOptions,
              ...clearedOptions,
            },
          });
          break;
        }
        case 'not_avail_multiroom_popup_show': {
          KM('MROOM_OFF_POPUP_SHOW', {
            obj: {
              ...additionalOptions,
              ...options,
            },
          });
          break;
        }
        case 'not_avail_multiroom_popup_apply': {
          KM('MROOM_OFF_POPUP_1room', {
            obj: {
              ...additionalOptions,
              ...options,
            },
          });
          break;
        }
        case 'not_avail_multiroom_popup_leave': {
          KM('MROOM_OFF_POPUP_MROOM', {
            obj: {
              ...additionalOptions,
              ...options,
            },
          });
          break;
        }
        case 'click_mobile_banner_download_app_link':
          KM('mobileAppBanner_downloadApp', {
            obj: {
              ...clearedOptions,
              ...additionalOptions,
            },
          });

          break;
        case 'slider_downloadApp':
          KM('slider_downloadApp', {
            obj: {
              ...clearedOptions,
              ...additionalOptions,
            },
          });

          break;
        case 'deals_click':
          KM('deals_click', {
            obj: {
              ...clearedOptions,
              ...additionalOptions,
            },
          });

          break;
        case 'main_offers_click':
        case 'main_offers_show':
          KM(type, {
            obj: {
              ...clearedOptions,
              ...additionalOptions,
            },
          });

          break;

        case 'activities_popular_direction_click':
          KM('popular_direction', {
            obj: {
              ...clearedOptions,
              ...additionalOptions,
            },
          });
          break;

        case 'activities_nearby_city_click':
          KM('nearby_city', {
            obj: {
              ...clearedOptions,
              ...additionalOptions,
            },
          });
          break;

        case 'activities_search_tag_click':
          KM('search_tag', {
            obj: {
              ...clearedOptions,
              ...additionalOptions,
            },
          });
          break;
        case 'email_unsubscribe_popup_show':
        case 'email_unsubscribe_popup_click':
          KM(type, {
            obj: {
              ...clearedOptions,
              ...additionalOptions,
            },
          });
          break;
        case 'explore_search_start':
        case 'explore_search_finish':
        case 'explore_filter':
        case 'explore_sort':
        case 'explore_click_product':
        case 'explore_click_heatmap':
        case 'main_screen_b2b_button_clicked':
        case 'meta-web-banner-show':
        case 'meta-web-banner-close-click':
        case 'meta-web-banner-download-click':
        case 'meta-web-banner-continue-click':
        case 'serp_b2b_click':
        case 'serp_cashback_wild_button':
          KM(type, {
            obj: {
              ...clearedOptions,
              ...additionalOptions,
            },
          });
          break;
        default:
          break;
      }
    });
  };
}

if (__CLIENT__) {
  metrics = metricsInitialize();
}

export default metrics;
