import { getAvailableTests } from '@ott/ab-test';

const availableTests: string[] = getAvailableTests();

// https://onetwotripdev.atlassian.net/browse/FI-833
// есть еще вариант m19_a, в этом случае не отображаем баннер
const META_WEB_BANNER_HIGH_VARIANT = 'm19_b';
const META_WEB_BANNER_COMPACT_VARIANT = 'm19_c';

export const checkIsMetaWebBannerHighVariant = () =>
  availableTests.includes(META_WEB_BANNER_HIGH_VARIANT);

export const checkIsMetaWebBannerCompactVariant = () =>
  availableTests.includes(META_WEB_BANNER_COMPACT_VARIANT);

/* -------------------------------------------------------------------------------------------------
 * Ссылка "Командировки
 * -----------------------------------------------------------------------------------------------*/
const B2B_TRIPS_BUTTON_INLINED = 'm28_a';
const B2B_TRIPS_BUTTON_SEPARATED = 'm28_b';

/**
 * Определяет положение ссылки "Командировки" на главной странице.
 * Если позиция не определена, не отображаем ссылку.
 * {@link https://onetwotripdev.atlassian.net/browse/REF-108|Задача}
 */
export const getB2bButtonPosition = () => {
  const availableTestsClosure = getAvailableTests();

  switch (true) {
    case availableTestsClosure.includes(B2B_TRIPS_BUTTON_SEPARATED):
      return 'separated';
    case availableTestsClosure.includes(B2B_TRIPS_BUTTON_INLINED):
    default:
      return 'inlined';
  }
};
