import { PRODUCTS } from 'src/components/pages/SurchargePage/constants';
import { pay as hotelsPay } from '../actions/hotels';

const getPayActionCreator = (product) => {
  switch (product) {
    case PRODUCTS.HOTELS:
      return hotelsPay;
    default:
      return () => {};
  }
};

export default getPayActionCreator;
