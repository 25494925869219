import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import HotelsSearchForm, { MultiroomWarning } from '@ott/hotels-search-form';

import { lang } from '@ott/l10n';
import metrics from 'utility/metrics';
import setFormReadyParam from 'utility/setFormReadyParam';

import getHotelsConfig from 'redux/modules/hotelsConfig/actions';
import useQuery from 'src/hooks/useQuery';
import usePreviousValue from 'src/hooks/usePreviousValue';

const [language, locale] = (lang || '').split('-');

const HotelsSearchFormWrapper = (props) => {
  const { urls, showBusinessTripCheckbox } = props;
  const query = useQuery();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.common.auth || {});
  const hotelsConfig = useSelector((state) => state.hotels.hotelsConfig || {});

  const submit = useCallback(
    ({ url, params }) => {
      metrics('start_search');
      metrics('start_hotels_search', params);

      const preparedQueryString = Object.keys(params)
        .map((key) => {
          if (!params[key]) {
            return null;
          }

          if (key === 'rooms') {
            return params[key].reduce((acc, room) => {
              if (!acc) {
                return `rooms[]=${room}`;
              }
              return acc + `&rooms[]=${room}`;
            }, '');
          }

          return `${key}=${params[key]}`;
        })
        .filter(Boolean)
        .join('&');

      window.location.href = `${urls.hotelsSearch}${url}?${preparedQueryString}`;
    },
    [urls]
  );

  const fetchHotelsConfig = () => {
    dispatch(
      getHotelsConfig({
        lang: language || 'ru',
        locale: locale || language || 'ru',
      })
    );
  };

  useEffect(() => {
    /**
     * TODO: Придумать как решить проблему без перезагрузки страницы
     *
     * В сафари наблюдалась проблема при возврате через кнопку back браузера
     * Оказалось, все дело в т.н. BF кэше. Здесь об этом подробнее
     * https://stackoverflow.com/questions/8788802/prevent-safari-loading-from-cache-when-back-button-is-clicked
     */

    const handlePageShow = (event) => {
      if (event.persisted) {
        window.location.reload();
      }
    };

    window.addEventListener('pageshow', handlePageShow);

    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);

  useEffect(() => {
    setFormReadyParam('hotels');
    fetchHotelsConfig();
  }, []);

  const { didEnter, didExit } = auth;
  const { didEnter: prevDidEnter, didExit: prevDidExit } = usePreviousValue(auth);

  useEffect(() => {
    if ((didEnter && !prevDidEnter) || (didExit && !prevDidExit)) {
      // При авторизации или выходе получаем повторно hotelsConfig
      fetchHotelsConfig();
    }
  });

  const { data: hotelsConfigData } = hotelsConfig;

  return (
    <>
      {!hotelsConfig.loading && (
        <MultiroomWarning query={query} context="ott" hotelsConfig={hotelsConfig} />
      )}
      <HotelsSearchForm
        context="ott"
        submitGtmLocator="hotel-search-mainform"
        hapi={urls.suggestUrls.hotels}
        hotelsConfig={hotelsConfigData}
        onSubmit={submit}
        query={query}
        backgroundTheme="dark"
        showBusinessTripCheckbox={showBusinessTripCheckbox}
      />
    </>
  );
};

HotelsSearchFormWrapper.propTypes = {
  urls: PropTypes.shape({
    suggestUrls: PropTypes.shape({
      hotels: PropTypes.string.isRequired,
    }).isRequired,
    hotelsSearch: PropTypes.string.isRequired,
  }),
};

export default HotelsSearchFormWrapper;
