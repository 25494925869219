
/**
 * Указываем тут true, если в данный момент проходит маркетинговая акция.
 * Обычно временно заменяется иконка трипкоина возле раздела "Отели" на новую иконку распродажи.
 * Последнее актуальное промо - {@link https://onetwotripdev.atlassian.net/browse/FI-928|Задача}
 */
export const IS_MARKETING_PROMO_AVAILABLE = false;

// Здесь указываем актуальный путь до промо иконки, как правило 20x20px
export const MARKETING_PROMO_ICON =
  'https://static.onetwotrip.com/images/index/cosmic_sale_promo_icon.svg';
