import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import qs from 'qs';

import _cloneDeep from 'lodash/cloneDeep';

import { helpers as virtualCardHelpers } from '@ott/shared-virtual-card-logic';

import { lang } from '@ott/l10n';
import HeaderAuth, { POSITION, MENU_ITEMS, headerAuthHelpers } from '@ott/header-auth';
import LogoIcon from '@ott/icon-ott-logo';
import AppleIcon from '@ott/icon-apple';
import AndroidIcon from '@ott/icon-android';

import { Container } from '@ott/grid';
import { authActions } from '@ott/auth';

import { withLayoutContext } from '@ott/layout-context';

import Nav from 'src/components/organisms/Nav';
import { S3_URL } from 'src/constants/common';
import metrics from 'utility/metrics';

import { showAuth, hideAuth } from 'src/redux/modules/common/authModal/actions';

import styles from './IndexHeader.scss';

const authTypeByAction = {
  openSignUp: 'SignupForm',
  openSignIn: 'SigninForm',
  openSignInByPhone: 'SigninByPhoneForm',
  openForgotPassword: 'ForgotPasswordForm',
};

@withLayoutContext
@connect()
class IndexHeader extends PureComponent {
  state = { isMenuOpen: false, isAuthShowedByQuery: false };

  static propTypes = {
    languageSwitcher: PropTypes.node,
  };
  static defaultProps = {
    languageSwitcher: null,
  };

  componentDidMount() {
    const {
      dispatch,
      config: { withVirtualCard },
    } = this.props;
    dispatch(
      authActions.getBasicUserInfo({
        withVirtualCard: Boolean(withVirtualCard),
      })
    );
  }

  componentDidUpdate(prevProps) {
    const { isAuthShowedByQuery } = this.state;
    const {
      dispatch,
      auth: { infoStatus, data },
      config: { withVirtualCard },
    } = this.props;
    const {
      auth: { infoStatus: prevInfoStatus },
      config: { withVirtualCard: prevWithVirtualCard },
    } = prevProps;

    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    if (!isAuthShowedByQuery && query.action) {
      if (prevInfoStatus.success && infoStatus.success) {
        this.setState({ isAuthShowedByQuery: true });

        if (!data || !data.auth) {
          dispatch(showAuth(authTypeByAction[query.action]));
        }
      }
    }

    if (prevWithVirtualCard !== withVirtualCard && withVirtualCard) {
      dispatch(
        authActions.getBasicUserInfo({
          withVirtualCard: Boolean(withVirtualCard),
        })
      );
    }
  }

  filterLinks(links = [], isGuestMode = null) {
    const result = links.filter((link) => {
      const { guestModeOnly } = link;

      if (guestModeOnly === undefined) {
        return true;
      }

      if (isGuestMode === null) {
        return false;
      }

      return guestModeOnly === isGuestMode;
    });

    result.forEach((link) => {
      if (link.links) {
        link.links = this.filterLinks(link.links, isGuestMode);
      }
    });

    return result;
  }

  renderNav() {
    const {
      config: { navigation: configNavigation },
      isGuestMode,
    } = this.props;

    const navigation = _cloneDeep(configNavigation);
    navigation.links = this.filterLinks(navigation.links, isGuestMode);

    return <Nav {...navigation} />;
  }

  renderApps() {
    const {
      config: { apps: { ios, android } = {}, header: { loyaltyLink = '' } = {} },
    } = this.props;

    return [
      ios && (
        <a
          key="ios_app"
          className={styles.mobileAppLink}
          href={loyaltyLink}
          rel={ios.rel}
          target={ios.target}
          data-locator="iosAppLink"
        >
          <AppleIcon fill={'#fff'} />
        </a>
      ),
      android && (
        <a
          key="android_app"
          className={styles.mobileAppLink}
          href={loyaltyLink}
          rel={android.rel}
          target={android.target}
          data-locator="androidAppLink"
        >
          <AndroidIcon fill={'#fff'} />
        </a>
      ),
    ];
  }

  renderLogo() {
    const {
      config: { partner },
    } = this.props;

    if (partner && partner.logo) {
      const logoPartnerParams = {
        href: `/${lang}/`,
      };
      if (partner.link) {
        logoPartnerParams.href = partner.link;
        logoPartnerParams.target = '_blank';
      }

      return (
        <Fragment>
          <a href={`/${lang}/`} className={styles.logo}>
            <LogoIcon />
          </a>
          <div className={styles.logoSeparator} />
          <a {...logoPartnerParams} className={styles['logo--partner']}>
            <img src={partner.logo.desktop} />
          </a>
        </Fragment>
      );
    }

    return (
      <a href={`/${lang}/`} className={styles.logo}>
        <LogoIcon w={40} />
      </a>
    );
  }

  renderWildCashbackBadge() {
    const {
      config: { product, partner },
    } = this.props;

    const href = `/${lang}/hotels/`;

    const products = ['avia', 'hotels', 'poezda', 'bus'];
    const hangerImageLowDensity = `${S3_URL}/index/hotels/wild-cashback-hanger@1x.png`;
    const hangerImageHighDensity = `${S3_URL}/index/hotels/wild-cashback-hanger@2x.png`;

    if (typeof window === 'undefined' || partner || !products.includes(product) || lang !== 'ru') {
      return null;
    }

    const isLinkDisabled = href === window.location.pathname;

    const onLinkClickHandler = (event) => {
      if (isLinkDisabled) {
        event.preventDefault();
        return;
      }
      metrics('serp_cashback_wild_button', {
        page_type: product,
      });
    };

    return (
      <a
        className={cls(
          styles.wildCashbackBadge,
          isLinkDisabled && styles.wildCashbackBadgeDisabled
        )}
        href={`/${lang}/hotels/`}
        onClick={onLinkClickHandler}
      >
        <img
          className={styles.wildCashbackHanger}
          src={hangerImageLowDensity}
          srcSet={`${hangerImageLowDensity} 20w, ${hangerImageHighDensity} 40w`}
          sizes="20px, 40px"
          alt="wild cashback hanger"
        />
        <span>
          Ловите дикий
          <br />
          кэшбэк на отели
        </span>
        <div className={styles.percentsBadge}>
          <span>до 30%</span>
        </div>
      </a>
    );
  }

  renderAuth() {
    const { auth, config, dispatch } = this.props;

    if (config && config.partner && config.partner.disableProfile) {
      return null;
    }

    const { exit, regReset, getBasicUserInfo } = authActions;

    const headerAuthActions = bindActionCreators(
      {
        exit,
        regReset,
        showAuth,
        hideAuth,
        getUserInfo: getBasicUserInfo,
      },
      dispatch
    );

    const dropdownMenuItems = headerAuthHelpers.getIndexDropdownMenuItemsConfig();
    const menuItems = headerAuthHelpers.getIndexMenuItemsConfig();

    const authHeaderSpecificLocators = {
      menuItems: {
        [MENU_ITEMS.mobileLogin]: 'inactive-mobileLogin',
      },
      authLabelAuthorized: 'desktopAuthLabelAuthorized',
      authLabelUnauthorized: 'desktopAuthLabelUnauthorized',
    };

    const userHasVirtualCard = virtualCardHelpers.hasVirtualCard(auth.data?.cards || []);

    return (
      <HeaderAuth
        partner={config.partner}
        position={POSITION.inplace}
        dropdownMenuItemsConfig={dropdownMenuItems}
        menuItemsConfig={menuItems}
        specificLocators={authHeaderSpecificLocators}
        onExit={() => headerAuthActions.exit()}
        onShowAuthButtonClick={() => headerAuthActions.showAuth()}
        userHasVirtualCard={userHasVirtualCard}
        isAuthLoading={auth.infoStatus?.loading}
        isAuthenticated={auth.data?.auth}
        userStatus={auth.data?.userStatus?.type}
      />
    );
  }

  renderLanguageSwitcher() {
    const { languageSwitcher, config } = this.props;
    if (config && config.partner && config.partner.disableLanguageSwitcher) {
      return null;
    }
    return languageSwitcher;
  }

  render() {
    const { className } = this.props;

    const navigationNode = this.renderNav();
    const authNode = this.renderAuth();
    const appsNode = this.renderApps();
    const logoNode = this.renderLogo();
    const wildCashbackNode = this.renderWildCashbackBadge();
    const languageSwitcherNode = this.renderLanguageSwitcher();

    return (
      <header className={cls(styles.container, className)} data-locator="IndexHeader">
        <Container className={styles.wrapper}>
          <div className={styles.logoWrapper}>
            {logoNode}
            {wildCashbackNode}
          </div>
          <div className={styles.nav}>
            {appsNode}
            {navigationNode}
            {authNode}
            {languageSwitcherNode}
          </div>
        </Container>
      </header>
    );
  }
}

export default IndexHeader;
