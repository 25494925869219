import React, { useEffect, useState } from 'react';
import Cookie from 'js-cookie';
import cls from 'classnames';

import { useLayoutContext } from '@ott/layout-context';
import Button from '@ott/button';
import CloseIcon from '@ott/icon-close';

import { checkIsMetaWebBannerCompactVariant, checkIsMetaWebBannerHighVariant } from 'utility/abTest';
import metrics from 'utility/metrics';
import styles from './MetaWebBanner.scss';

type Props = {
  title: string;
  titleAlternate: string;
  buttonCaption: string;
  closeAndContinue: string;
  images: {
    highBanner: string;
    shortBanner: string;
  };
  links: {
    highBanner: string;
    shortBanner: string;
  };
};

const isMetaWebBannerHighVariant = checkIsMetaWebBannerHighVariant();
const isMetaWebBannerCompactVariant = checkIsMetaWebBannerCompactVariant();

const referrer = Cookie.get('referrer');
const RESELLERS = ['aviasales', 'yandex'];
const isMetaWebBannerEnabled =
  referrer &&
  RESELLERS.includes(referrer) &&
  (isMetaWebBannerHighVariant || isMetaWebBannerCompactVariant);

const MetaWebBanner = (props: Props) => {
  const { title, titleAlternate, links } = props;
  const { isMobile } = useLayoutContext();

  const [isMounted, setIsMounted] = useState(false);
  const [isVisible, setIsVisible] = useState(isMetaWebBannerEnabled && isMobile);

  const bannerTitle = isMetaWebBannerHighVariant ? title : titleAlternate;
  const downloadLink = isMetaWebBannerHighVariant ? links.highBanner : links.shortBanner;

  useEffect(() => {
    // для того чтоб успела воспроизвеcтись анимация появления баннера
    setTimeout(() => {
      setIsMounted(true);
    }, 500);
  }, []);

  useEffect(() => {
    if (isVisible) {
      metrics('meta-web-banner-show');
    }
  }, [isVisible]);

  useEffect(() => {
    // баннер отображаем поверх всей главной
    // поэтому чтобы задизейблить скролл вешаем overflow: hidden на body
    const bodyElement = document.getElementsByTagName('body')[0] as HTMLBodyElement;

    if (isVisible) {
      bodyElement.style.overflow = 'hidden';
    } else {
      bodyElement.style.overflow = 'auto';
    }
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.container} data-locator="meta-web-banner-container">
      <div
        className={cls(styles.banner, {
          [styles.mounted]: isMounted,
        })}
        data-locator="meta-web-banner-banner"
      >
        <button
          type="button"
          className={styles.closeButton}
          data-locator="meta-web-banner-close-button"
          onClick={() => {
            setIsVisible(false);
            metrics('meta-web-banner-close-click');
          }}
        >
          <CloseIcon className={styles.closeIcon} />
        </button>

        {isMetaWebBannerHighVariant && (
          <img
            className={styles.headerImage}
            src={props.images.highBanner}
            alt="meta-web-banner-header"
          />
        )}

        <div className={styles.bannerContent} data-locator="meta-web-banner-content">
          {isMetaWebBannerCompactVariant && (
            <img
              className={styles.bannerLogo}
              src={props.images.shortBanner}
              alt="meta-web-curtain-logo"
            />
          )}
          <div className={styles.title}>{bannerTitle}</div>
          <Button
            className={styles.downloadButton}
            href={downloadLink}
            onClick={() => metrics('meta-web-banner-download-click')}
            specificLocators="meta-web-banner-download-button"
          >
            {props.buttonCaption}
          </Button>
          <Button
            className={styles.continueButton}
            onClick={() => {
              setIsVisible(false);
              metrics('meta-web-banner-continue-click');
            }}
            specificLocators="meta-web-banner-continue-button"
          >
            {props.closeAndContinue}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MetaWebBanner;
