import { setIsPaymentInProgress, setPayError } from 'src/redux/modules/surcharge/actions';
import { constants as creditCardConstants } from '@ott/credit-card-deprecated/src';
import { PAYMENT_METHODS } from 'src/components/pages/SurchargePage/constants';
import superagent from '@ott/superagent';
import { fetchAviaSurchargeData } from 'src/redux/modules/surcharge/actions/avia/fetchAviaSurchargeData';

const paymentUrl = '/_api/process/paynewpayment/';

type PayNewPaymentResponse = {
  status: 'Canceled' | 'Captured' | 'WaitingForPayment' | 'Refunded' | 'Blocked';
  id: string;
};

type PayNewPaymentError = {
  error: 'CANT_BLOCK_MONEY' | 'NEED_CARD_AUTHORIZATION' | 'AUTHORIZATION_CARD_ERROR';
};

export const startPayment = () => async (dispatch, getState) => {
  const { surcharge, form } = getState();
  const {
    common: { currentGate, surchargeId },
  } = surcharge;

  if (!currentGate) {
    dispatch(setPayError());

    return;
  }

  const { method } = currentGate;

  dispatch(setIsPaymentInProgress(true));

  if (method === PAYMENT_METHODS.BANK_CARD) {
    const creditCardData = form?.[creditCardConstants.PAY_FORM]?.values;

    if (!creditCardData) {
      return;
    }

    const { cardCVV, cardHolder, cardNumber, expMonth, expYear } = creditCardData;

    const paymentParams = {
      creditCard: {
        number: cardNumber,
        expDate: expMonth + expYear,
        holderName: cardHolder.replace(/ /gm, '+'),
        cvv: cardCVV,
        //appCode: 'iyfq9f'
        cuid: null,
      },
      id: surchargeId,
    };

    const formData = `params=${encodeURIComponent(JSON.stringify(paymentParams))}`;

    const response = (await superagent
      .post(paymentUrl)
      .send(formData)
      .then((response) => response.body)) as PayNewPaymentResponse | PayNewPaymentError;

    dispatch(setIsPaymentInProgress(false));

    if ('error' in response) {
      return dispatch(setPayError());
    }

    dispatch(fetchAviaSurchargeData(surchargeId));
  }
};
