import 'src/styles/base-settings.css';
import 'intersection-observer';
import '@ott/styles/css-vars-default.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import middleware from 'src/redux/middleware';
import configureStore from 'src/redux/store';
import { setSSR } from 'src/redux/modules/common/ssr/actions';

import 'utility/splitmetrics';
import 'utility/affilateReferrer';
import createGoogleTagManager from '@ott/google-tag-manager';

import Root from './Root';

import history from './helpers/history';

const store = configureStore(middleware, window.preloadedState);

const container = document.getElementById('app');

setTimeout(() => {
  createGoogleTagManager();
}, 1000);

if (__DEV__ || __WHITE_LABEL__) {
  ReactDOM.render(<Root store={store} history={history} />, container, () => {
    store.dispatch(setSSR(false));
  });
} else {
  ReactDOM.hydrate(<Root store={store} history={history} />, container, () => {
    store.dispatch(setSSR(false));
  });
}
